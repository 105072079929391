import React, { useRef, useEffect, useState } from "react";

const MandelbrotPlot = () => {
  const canvasRef = useRef(null);
  const [state, setState] = useState({
    xCenter: -0.7436438870371587, // Seahorse Valley
    yCenter: 0.13182590420531197,
    zoom: 1,
    maxIterations: 100,
  });
  const [isBusy, setIsBusy] = useState(false); // Prevent overlapping calculations

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const { xCenter, yCenter, zoom, maxIterations } = state;

    const width = canvas.width;
    const height = canvas.height;

    const xMin = xCenter - (4 / zoom) / 2;
    const xMax = xCenter + (4 / zoom) / 2;
    const yMin = yCenter - (4 / zoom) / 2;
    const yMax = yCenter + (4 / zoom) / 2;

    // Dynamically create the worker script
    const workerScript = createWorkerScript();
    const workerBlob = new Blob([workerScript], { type: "application/javascript" });
    const worker = new Worker(URL.createObjectURL(workerBlob));

    // Indicate that the worker is busy
    setIsBusy(true);

    // Send data to the worker
    worker.postMessage({ width, height, xMin, xMax, yMin, yMax, maxIterations });

    // Listen for worker responses
    worker.onmessage = ({ data }) => {
      const pixels = new Uint8ClampedArray(data);
      const imageData = new ImageData(pixels, width, height);
      ctx.putImageData(imageData, 0, 0); // Render the calculated Mandelbrot set

      // Free up the worker for the next calculation
      setIsBusy(false);
    };

    return () => worker.terminate(); // Clean up the worker on component unmount
  }, [state]);

  // Infinite zoom logic with synchronization
  useEffect(() => {
    const zoomFactor = 1.05; // Zoom in by 5%
    const zoomSpeed = 50; // Frequency of zoom updates (ms)

    const zoomIn = () => {
      // Only proceed if the worker is not busy
      if (!isBusy) {
        setState((prev) => ({
          xCenter: prev.xCenter,
          yCenter: prev.yCenter,
          zoom: prev.zoom * zoomFactor,
          maxIterations: prev.maxIterations + 5, // Increase detail with zoom
        }));
      }
    };

    const intervalId = setInterval(zoomIn, zoomSpeed);
    return () => clearInterval(intervalId); // Clear interval on unmount
  }, [isBusy]);

  return (
    <div>
      <canvas ref={canvasRef} width={800} height={600}></canvas>
      {/* <p>
        Zooming into: x = {state.xCenter}, y = {state.yCenter}, zoom ={" "}
        {state.zoom.toFixed(2)}, maxIterations = {state.maxIterations}
      </p> */}
    </div>
  );
};

export default MandelbrotPlot;

// Create the worker script inline
const createWorkerScript = () => `
  self.onmessage = ({ data }) => {
    const { width, height, xMin, xMax, yMin, yMax, maxIterations } = data;

    const pixels = new Uint8ClampedArray(width * height * 4);

    const mapRange = (value, inMin, inMax, outMin, outMax) => {
      return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    };

    for (let px = 0; px < width; px++) {
      for (let py = 0; py < height; py++) {
        const x0 = mapRange(px, 0, width, xMin, xMax);
        const y0 = mapRange(py, 0, height, yMin, yMax);

        let x = 0;
        let y = 0;
        let iteration = 0;

        while (x * x + y * y <= 4 && iteration < maxIterations) {
          const xTemp = x * x - y * y + x0;
          y = 2 * x * y + y0;
          x = xTemp;
          iteration++;
        }

        const pixelIndex = (py * width + px) * 4;
        const gradient = Math.sqrt(iteration / maxIterations) * 255; // Smooth gradient
        pixels[pixelIndex] = gradient; // Red
        pixels[pixelIndex + 1] = gradient * 0.7; // Green
        pixels[pixelIndex + 2] = gradient * 0.3; // Blue

        pixels[pixelIndex + 3] = 255; // Alpha
      }
    }

    self.postMessage(pixels.buffer, [pixels.buffer]);
  };
`;
